import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import styles from './userBar.module.scss'

import logo from '../../assets/images/logo.png'
import icons from '../../assets/icons'
import { AuthContext } from '../../context/AuthContext';

const Logo = () => (
  <Link to="/" className={styles.logo}>
    <img src={logo} alt='Dusof DataSync' />
  </Link>
);

const Username = () => {
  const { auth, logout } = useContext( AuthContext )
  const navigate = useNavigate()

  const [menu, setMenu] = useState(false);
  const menuToggle = () => setMenu(!menu);
  const delayedToggle = () => {
    setTimeout(() => {
      setMenu(!menu)
    }, 100);
  };

  const checkLogout = async() => {
    try {
      const resp = await logout()
      if ( resp ) {
        navigate('/login', { replace: true })
      }
    } catch( err ) {
      console.log(err)
    }
  }

  return (
    <div className={styles.username}>
      <div className={styles.usernameButton}>
        <button type="button" onClick={menuToggle}>
          <span>{auth.email}</span>
          <span>
            <img src={icons.expand} alt="Menu" />
          </span>
        </button>
      </div>
      <div className={`${styles.usernameMenu} ${menu ? '' : styles.usernameMenuHidden}`}>
        <button type="button"
        onClick={ checkLogout }
        >Cerrar sesión</button>
        <Link to="/reset-pass" onClick={delayedToggle}>Cambiar contraseña</Link>
      </div>
    </div>
  );
}

const UserBar = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Logo />
        <Username />
      </div>
    </div>
  );
};

export default UserBar;
