import React from 'react'
import styles from './pagination.module.scss'

const Button = ({ index, isActive, setPage }) => {
  return (
    <button
      type="button"
      onClick={setPage}
      className={isActive ? styles.buttonActive : ''}
    >
      {index}
    </button>
  )
}

const Pagination = ({ totalItems, currentPage, setCurrentPage }) => {
  // total de items entre cantidad de items a mostrar en cada página regresa la cantidad de botones
  const buttonCount = totalItems / 200
  // cantidad de botones redondeada
  const fixedCount = Math.ceil(buttonCount)
  // calcular si se requiere un botón extra para una última página con cantidad de items menor a las anteriores
  const lastButton = buttonCount > fixedCount

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const nextPage = () => {
    if ( currentPage !== fixedCount ) {
      setCurrentPage(currentPage + 1)
    } else {
      return
    }
  }

  const previousPage = () => {
    if ( currentPage !== 1 ) {
      setCurrentPage(currentPage - 1)
    } else {
      return
    }
  }

  const lastPage = () => {
    setCurrentPage(fixedCount)
  }

  const firstPage = () => {
    setCurrentPage(1)
  }

  return (
    <div className={styles.container}>
      <div className={styles.total}>
        <p>
          Items: <b>{totalItems}</b>
        </p>
        <p>
          Páginas: <b>{lastButton ? fixedCount + 1 : fixedCount}</b>
        </p>
      </div>
      <div className={styles.buttons}>
        <div className={styles.buttonsBase}>
          <button type="button"
            onClick={ () => previousPage() }
          >Anterior</button>
          <button type="button"
            onClick={ () => nextPage() }
          >Siguiente</button>
        </div>
        <div className={styles.buttonsBase}>
          <button type="button"
            onClick={ () => firstPage() }
          >Primera</button>
          <button 
            onClick={ () => lastPage() }
          type="button">Última</button>
        </div>
        <div className={styles.buttonsPages}>
          {Array.from({ length: fixedCount }, (_, index) => (
            <Button
              key={ index }
              index={ index + 1 }
              isActive={index + 1 === currentPage ? true : false}
              setPage={() => handlePageChange( index + 1 )}
            />
          ))}
          {/* {lastButton && <Button index={31} isActive={false} />} */}
        </div>
      </div>
    </div>
  )
}

export default Pagination
