import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

import Header from '../../components/reuse/header';
import ParametersComponent from '../../components/parameters';
import apiParameters from '../../api/apiParameters';

const Parameters = () => {
  const [parameters, setParameters] = useState({})

  useEffect(() => {
    (async ()=> {
      try {
        const getParameters = await apiParameters.getParameters()
        const { cronjob, minInventory } = getParameters.response
        const splitCronjob = cronjob.split(' ')
        setParameters({
          cronjob: splitCronjob[1],
          minInventory: minInventory
        })

        console.log(parameters)
      } catch( err ) {
        console.log( err )
      }
    })()
  },[setParameters])

  return (
    <div className={styles.container}>
      <Header title="Parametrización" description="Edita la hora de ejecución de la sincronización automática y/o el mínimo de inventario." />
      <div className={styles.content}>
        <ParametersComponent cronjob={ parameters.cronjob } minInventory={ parameters.minInventory } setParameters={ setParameters }/>
      </div>
    </div>
  );
}

export default Parameters;
