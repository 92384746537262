import React from 'react';
import styles from './styles.module.scss';

import LoginComponent from '../../components/login'

const Login = () => {
  return (
    <div className={styles.container}>
      <LoginComponent />
    </div>
  );
};

export default Login;
