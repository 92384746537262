import React from 'react';
import styles from './header.module.scss';

const Header = ({ title, description }) => (
  <div className={styles.container}>
    <p>{title}</p>
    <p>{description}</p>
  </div>
);

export default Header;
