import { types } from '../types/types'

export const socketReducer = ( state, action ) => {
    switch (action.type) {
        case types.processStarted:
            return {
                ...state,
                processStarted: action.payload
            }
        case types.updateExelInventory:
            return {
                ...state,
                exel: action.payload
            }
        case types.updateCvaInventory:
            return {
                ...state,
                cva: action.payload
            }
        case types.updateGLomaInventory:
            return {
                ...state,
                gloma: action.payload
            }
        case types.updateIngramInventory:
            return {
                ...state,
                ingram: action.payload
            }
        case types.createProducts:
            return {
                ...state,
                vtex: action.payload
            }
        default:
            return state
    }
}