import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

import Header from '../../components/reuse/header';
import Category from '../../components/category';
import apiVtex from '../../api/apiVtex';

const Categories = () => {
  const [ categories, setCategories ] = useState([])

  const updateCategories = () => {
    (async()=>{
      try {
        const categories = await apiVtex.getAllCategories()
        const { response } = categories
        setCategories(response)
      }
      catch(err) {
        console.log(err)
      }
    })()
  }

  useEffect(() => {
    updateCategories()
  },[setCategories])


 
  const listCategories = categories.map((category) => (
    <Category key={category.id} category={category} setCategories={ setCategories }/>
  ))

  return (
    <div className={styles.container}>
      <Header title="Ganancias por categoría" description="Edita en porcentaje la cantidad de ganancia que deseas asignar a cada sub-categoría." />
      <div className={styles.content}>
        { listCategories }
      </div>
    </div>
  );
};

export default Categories;
