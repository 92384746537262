import React from 'react';
import { Link } from 'react-router-dom';
import styles from './box.module.scss';

import icons from '../../assets/icons';
import Loading from '../reuse/loading';

export const SyncBox = () => {
  const processRunning = false;
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <img src={icons.sync} alt='Sincronización' />
      </div>
      <div className={styles.title}>
        <p>Sincronización</p>
      </div>
      {processRunning ? (
        <div className={styles.loading}>
          <p>Hay un proceso ejecutándose</p>
          <Loading />
        </div>
      ) : (
        <div className={styles.links}>
          <Link to="/sync/ingram">Ingram</Link>
          <Link to="/sync/cva">CVA</Link>
          <Link to="/sync/gloma">GLoma</Link>
          <Link to="/sync/exel">Excel</Link>
        </div>
      )}
    </div>
  );
}

export const UploadBox = () => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <img src={icons.upload} alt='Alta de productos' />
      </div>
      <div className={styles.title}>
        <p>Alta de productos</p>
      </div>
      <div className={styles.links}>
        <Link to="/upload/ingram">Ingram</Link>
        <Link to="/upload/cva">CVA</Link>
        <Link to="/upload/gloma">GLoma</Link>
        <Link to="/upload/exel">Excel</Link>
      </div>
    </div>
  );
}

export const CategoryBox = () => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <img src={icons.category} alt='Ganancias por categoría' />
      </div>
      <div className={styles.title}>
        <p>Ganancias por categoría</p>
      </div>
      <div className={styles.links}>
        <Link to="/categories">Entrar</Link>
      </div>
    </div>
  );
}

export const ReportBox = () => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <img src={icons.report} alt='Reporte de altas' />
      </div>
      <div className={styles.title}>
        <p>Reporte de altas</p>
      </div>
      <div className={styles.links}>
        <Link to="/report">Entrar</Link>
      </div>
    </div>
  );
}

export const UserBox = () => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <img src={icons.users} alt='Alta de usuarios' />
      </div>
      <div className={styles.title}>
        <p>Alta de usuarios</p>
      </div>
      <div className={styles.links}>
        <Link to="/users">Entrar</Link>
      </div>
    </div>
  );
}

export const ParametersBox = () => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <img src={icons.parameters} alt='Parametrización' />
      </div>
      <div className={styles.title}>
        <p>Parametrización</p>
      </div>
      <div className={styles.links}>
        <Link to="/parameters">Editar</Link>
      </div>
    </div>
  );
}
