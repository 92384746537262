import React from 'react';
import styles from './table.module.scss';

const Titles = () => {
  return (
    <div className={styles.titles}>
      <div>
        <p>Fecha</p>
      </div>
      <div>
        <p>SKU</p>
      </div>
      <div>
        <p>Distribuidor</p>
      </div>
      <div>
        <p>Categoría</p>
      </div>
      <div>
        <p>Descripción</p>
      </div>
    </div>
  );
}

const Row = ( { report } ) => {
  return (
    <div className={styles.row}>
      <div>
        <p>{report.createdDate}</p>
      </div>
      <div>
        <p>{report.SKU}</p>
      </div>
      <div>
        <p>{report.Distributor}</p>
      </div>
      <div>
        <p>{report.categoryText}</p>
      </div>
      <div>
        <p>{report.Description}</p>
      </div>
    </div>
  );
}

const Table = ( { reports } ) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Titles />
        {
          (reports || []).map( report => (
            <Row report={ report }/>
          ))
        }
      </div>
    </div>
  );
}

export default Table;
