import React, { useContext, useEffect, useState } from "react";
import styles from "./list.module.scss";

import icons from "../../assets/icons";
import apiUser from "../../api/apiUsers";
import { AuthContext } from "../../context/AuthContext";

const Role = ({ setRoleModal, user }) => {

  const [ role, setRole ] = useState('user')

  const updateRole = async () => {
    // functionality
    try {
      const response = await apiUser.changeRole(user._id, role)
      console.log(response)
    } catch( err ) {
      console.log(err)
    }
    setRoleModal(false);
  };

  const changeRole = (e) => {
    setRole(e.target.value)
    console.log(user)
  }

  return (
    <div className={styles.role}>
      <p>Asignar rol a {user?.email}</p>
      <form className={styles.roleOptions}>
        <label>
          <input type='radio' value='user' name='role' onChange={changeRole} defaultChecked />
          <span>User</span>
        </label>
        <label>
          <input type='radio' value='admin' name='role' onChange={changeRole} />
          <span>Admin</span>
        </label>
        <div className={styles.modalButtons}>
          <button type='button' onClick={() => setRoleModal(false)}>
            Cancelar
          </button>
          <button type='button' onClick={updateRole}>
            Guardar
          </button>
        </div>
      </form>
    </div>
  );
};

const Delete = ({ setDeleteModal, user, setActionUser }) => {

  const deleteUser = async () => {
    // functionality
    setActionUser(true)
    try {
      const response = await apiUser.deleteUser(user?._id)
      if( response ){
        setActionUser(false)
      }
    } catch( err ) {
      console.log(err)
    }
    setDeleteModal(false)
  }

  return (
    <div className={styles.delete}>
      <p>¿Eliminar a {user?.email}?</p>
      <div className={styles.modalButtons}>
        <button type='button' onClick={() => setDeleteModal(false)}>
          Cancelar
        </button>
        <button type='button' onClick={deleteUser}>
          Eliminar
        </button>
      </div>
    </div>
  );
};

const Modal = ({ children }) => {
  return <div className={styles.modal}>{children}</div>;
};

const User = ({ user, setActionUser }) => {
  const [roleModal, setRoleModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [disableDelete, setDisableDelete] = useState(false)

  const { auth } = useContext( AuthContext )

  useEffect(() => {
    if( user?.email === auth?.email ){
      setDisableDelete(true)
    } else {
      setDisableDelete(false)
    }
  }, [auth, user])

  return (
    <div className={styles.user}>
      <div className={styles.userContent}>
        <div className={styles.username}>
          <p>{`${user.name} ${user.lastname}`}</p>
          <p>{user.email}</p>
        </div>
        <div className={styles.userButtons}>
          <button type='button' onClick={() => setRoleModal(!roleModal)}>
            <img src={icons.edit} alt='Editar rol' />
          </button>
          <button type='button' onClick={() => setDeleteModal(!deleteModal)} disabled={disableDelete}>
            <img src={icons.trash} alt='Eliminar' />
          </button>
        </div>
      </div>
      {roleModal && (
        <Modal>
          <Role roleModal={roleModal} setRoleModal={setRoleModal} user={user} />
        </Modal>
      )}
      {deleteModal && (
        <Modal>
          <Delete deleteModal={deleteModal} setDeleteModal={setDeleteModal} user={user} setActionUser={setActionUser} />
        </Modal>
      )}
    </div>
  );
};

const List = ({ actionUser, setActionUser }) => {
  
  const [ users, setUsers ] = useState([])
  

  useEffect(() => {
    (async () => {
      try {
        const response = await apiUser.getUsers(1, 50)
        if( response && response?.response ){
          setUsers(response?.response?.usersFinded)
        }
      } catch( err ) {
        console.log(err)
      }
    })()
  }, [actionUser])

  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {users?.map((user) => (
          <User user={user} setActionUser={setActionUser} />
        ))}
      </div>
    </div>
  );
};

export default List;
