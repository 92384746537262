import { createContext, useEffect, useState } from "react";
import httpClient from "../api/httpClient";
import { urlBase64Decode } from "../utils/helpers";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const socketSwal = withReactContent(Swal)

export const AuthContext = createContext();

const initialState = {
  logged: false,
  checking: true,
  name: null,
  email: null,
  token: null,
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(initialState);

  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkAuth = async () => {
    const token = sessionStorage.getItem("auth_token");
    if (token) {
      const decoded = await decodeJWTPayload(token);
      const expToken = getExpTokenDate(decoded);
      if (new Date() < expToken) {
        setAuth({
          logged: true,
          checking: false,
          name: decoded.user.name,
          email: decoded.user.email,
          token: token,
        });

        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const login = async (email, password) => {
    try {
      const resp = await httpClient.post("user/login", { email, password });
      if (resp.ok) {
        sessionStorage.setItem("auth_token", resp.token);
        const token = await decodeJWTPayload(resp.token);
        const { user } = token;
        setAuth({
          logged: true,
          checking: false,
          name: user.name,
          email: user.email,
          token: resp.token,
        });
        return resp.ok;
      }

      if ( resp.code == 401 ) {
        socketSwal.fire({
          title: <p>Error al loguearse</p>,
          html: `<p>email o contraseña incorrectos</p>`,
          confirmButtonColor: '#F57E00'
        })
      }
    } catch (err) {
      console.log(err);
    }
  };

  const logout = () => {
    return new Promise((resolve, reject) => {
      try {
        sessionStorage.removeItem("auth_token");
        setAuth({
          logged: false,
          checking: false,
          token: null,
        });

        return resolve(true);
      } catch (err) {
        return reject(err);
      }
    });
  };

  const createUser = async (user) => {
    try {
      const resp = await httpClient.post("user/create", user);
      return resp;
    } catch (err) {
      console.log(err);
    }
  };

  const getToken = () => {
    return sessionStorage.getItem("auth_token");
  };

  const getExpTokenDate = (payload) => {
    if (payload && !payload.hasOwnProperty("exp")) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(payload.exp);
    return date;
  };

  const isValid = () => {
    return !getExpTokenDate || new Date() < getExpTokenDate();
  };

  const decodeJWTPayload = (payload) => {
    return new Promise((resolve, reject) => {
      if (payload.length === 0) {
        return reject("Empty token");
      }

      const parts = payload.split(".");

      if (parts.length !== 3) {
        return reject(`The payload ${payload} is invalid`);
      }

      let decoded;

      try {
        decoded = urlBase64Decode(parts[1]);
      } catch (e) {
        return reject(`Payload ${payload} invalid`);
      }

      if (!decoded) {
        return reject(`Payload ${payload} canot be decoded`);
      }

      return resolve(JSON.parse(decoded));
    });
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        login,
        logout,
        createUser,
        getToken,
        checkAuth,
        isValid,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
