import sync from './sync.svg';
import upload from './upload.svg';
import category from './category.svg';
import expand from './expand.svg';
import report from './report.svg';
import users from './users.svg';
import check from './check.svg';
import checkEmpty from './check-empty.svg';
import checkDone from './check-done.svg';
import categoryPlus from './category-plus.svg';
import categoryMinus from './category-minus.svg';
import parameters from './parameters.svg';
import edit from './edit.svg';
import trash from './delete.svg';

const icons = {
  sync,
  upload,
  category,
  expand,
  report,
  users,
  check,
  checkEmpty,
  checkDone,
  categoryPlus,
  categoryMinus,
  parameters,
  edit,
  trash
}

export default icons;
