import React from 'react';
import styles from './styles.module.scss';

import DashboardComponent from '../../components/dashboard/dashboard';

const Dashboard = () => {
  return (
    <div className={styles.container}>
      <DashboardComponent />
    </div>
  );
};

export default Dashboard;
