import React from 'react';
import styles from './styles.module.scss';

import ResetPassComponent from '../../components/pass/reset'

const ResetPass = () => {
  return (
    <div className={styles.container}>
      <ResetPassComponent />
    </div>
  );
};

export default ResetPass;
