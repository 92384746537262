import httpClient from './httpClient'

const path = 'admin'

const apiAdmin = {
    async updateWinPercentage( categories ) {
        try {
            const response = await httpClient.put(`${path}/updateWinPercentage`, {categories: categories})
            return response
        } catch ( err ) {
            return err
        }
    }
}

export default apiAdmin