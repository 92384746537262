import React, { useState } from 'react';
import styles from './styles.module.scss';

import Header from '../../components/reuse/header';
import List from '../../components/users/list';
import Create from '../../components/users/create';

const Users = () => {

  const [ actionUser, setActionUser ] = useState(false)

  return (
    <div className={styles.container}>
      <Header title="Usuarios" description="Agrega, elimina o edita los accesos de tus usuarios." />
      <div className={styles.content}>
        <Create setActionUser={setActionUser} />
        <List actionUser={actionUser} setActionUser={setActionUser} />
      </div>
    </div>
  );
}

export default Users;
