import React, { useState } from 'react'
import styles from './styles.module.scss'

import icons from '../../assets/icons'
import apiAdmin from '../../api/apiAdmin'
import apiVtex from '../../api/apiVtex'

const Item = (props) => {
  const chooseSubCategory = (e) => {
    if (e.target.value !== '') {
      let percentageObject = {
        id: props.subcategory.id,
        winPercentage: e.target?.value,
      }

      let existPercentage = props.percentage?.find(
        (percent) => percent.id === props.subcategory.id
      )

      if (!existPercentage) {
        props.setPercentage([...props.percentage, percentageObject])
      } else {
        // eslint-disable-next-line
        const index = props.percentage.findIndex((elemento) => {
          if (elemento.id === props.subcategory.id) {
            return true
          }
        })

        props.percentage[index] = percentageObject
        props.setPercentage(props.percentage)
      }
    }
  }

  return (
    <div className={styles.item} key={props.subcategory.id}>
      <span className={styles.itemLine} />
      <p className={styles.itemName}>{props.subcategory.name}</p>
      <div className={styles.itemInput}>
        <input
          type="text"
          id={props.subcategory.id}
          onBlur={chooseSubCategory}
          placeholder={props.subcategory.winPercentage}
        />
      </div>
    </div>
  )
}

const Subs = (props) => {
  const [percentage, setPercentage] = useState([])

  const updateCategories = () => {
    ;(async () => {
      try {
        const categories = await apiVtex.getAllCategories()
        const { response } = categories
        props.setCategories(response)
      } catch (err) {
        console.log(err)
      }
    })()
  }

  const test = async (id) => {
    try {
      const updateWinPercentage = await apiAdmin.updateWinPercentage(percentage)
      if (updateWinPercentage.ok) {
        props.setExpanded(false)
        updateCategories()
      }
    } catch (err) {
      console.log(err)
      props.setExpanded(false)
    }
  }

  return (
    <div
      className={props.expanded ? styles.subsExpanded : styles.subs}
      key={props.key}
    >
      <div className={styles.subsContent}>
        {props.subcategories.map((subcategory) => (
          <Item
            subcategory={subcategory}
            key={subcategory.id}
            setPercentage={setPercentage}
            percentage={percentage}
          />
        ))}
        <button
          type="button"
          className={styles.subsButton}
          onClick={() => test(props.categoryId)}
        >
          Guardar
        </button>
      </div>
    </div>
  )
}

const Category = (props) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <div className={styles.container} key={props.category.key}>
      <div className={styles.toggle}>
        <button type="button" onClick={() => setExpanded(!expanded)}>
          <span>
            <img
              src={expanded ? icons.categoryMinus : icons.categoryPlus}
              alt={props.category.name}
            />
          </span>
          <span>{props.category.name}</span>
        </button>
      </div>
      {expanded && (
        <Subs
          categoryId={props.category.id}
          subcategories={props.category.children}
          expanded={expanded}
          setExpanded={setExpanded}
          setCategories={props.setCategories}
        />
      )}
      <div className={styles.containerEnd} />
    </div>
  )
}

export default Category
