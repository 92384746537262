import axios from "axios";

const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
})

httpClient.interceptors.request.use((config) => {
  const apiToken = sessionStorage.getItem("auth_token");
  if (apiToken) {
    config.headers = { Authorization: apiToken };
  }

  return config;
});

httpClient.interceptors.response.use(
  (response) => response.data,
  (error) => error.response.data
);

export default httpClient;
