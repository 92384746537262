import { useCallback, useEffect, useState } from "react";
import { io } from "socket.io-client";

const useSocket = (serverPath) => {
  const [socket, setSocket] = useState(null);
  const [online, setOnline] = useState(false);

  const connectSockets = useCallback(
    (token) => {
      const socketTemp = io(serverPath, {
        autoConnect: true,
        forceNew: true,
        extraHeaders: { authorization: token },
      });

      setSocket(socketTemp);
    },
    [serverPath]
  );

  const disconnectSockets = useCallback(() => {
    socket?.disconnect();
  }, [socket]);

  useEffect(() => {
    setOnline(socket?.connected);

    socket?.on("connect", () => {
      setOnline(true);
      console.log("connected");
    });
  }, [socket]);

  useEffect(() => {
    socket?.on("updateCategories", (payload) => {
      console.log(payload);
    });
  }, [socket]);

  useEffect(() => {
    socket?.on("disconnect", () => {
      setOnline(false);
    });
  }, [socket]);

  return {
    socket,
    online,
    connectSockets,
    disconnectSockets,
  };
};

export default useSocket;
