import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import styles from './reset.module.scss';
import apiUser from '../../api/apiUsers'

import logo from '../../assets/images/logo.png';
import { AuthContext } from '../../context/AuthContext';

const ResetPass = () => {

  const [showError, setShowError] = useState(false)
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')

  const { auth } = useContext( AuthContext )
  const navigate = useNavigate()

  const changeCurrentPassword = ( e ) => {
    let passwordValue = e.target.value
    if( passwordValue ){
      setPassword(passwordValue)
    }
  }

  const onChangeNewPassword = ( e ) => {
    let newPasswordValue = e.target.value
    if( newPasswordValue ){
      setNewPassword(newPasswordValue)
    }
  }

  const onChangePasswordRepeat = ( e ) => {
    let newPasswordValue = e.target.value
    setShowError(true)
    if( newPasswordValue ){
      setRepeatPassword(newPasswordValue)

      if( newPassword === newPasswordValue ) {
        setShowError(false)
      }
    }
  }

  const changePassword = async() => {
    try {
      const response = await apiUser.changePassword(auth?.email, password, newPassword)
      if( response ){
        navigate('/', { replace: true })
      }
    } catch( err ) {
      console.log(err)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src={logo} alt='Dusof' />
      </div>
      <form className={styles.form}>
        <div className={styles.field}>
          <p>Contraseña actual</p>
          <label>
            <input type='password' onBlur={changeCurrentPassword} />
          </label>
        </div>
        <div className={styles.field}>
          <p>Nueva contraseña</p>
          <label>
            <input type='password' onBlur={onChangeNewPassword} />
          </label>
        </div>
        <div className={styles.field}>
          <p>Repetir nueva contraseña</p>
          <label>
            <input type='password' onChange={onChangePasswordRepeat} />
          </label>
        </div>
        {
          showError &&
          <p className={styles.span}>Las contraseñas deben coincidir</p>
        }
        <div className={styles.text}>
          <p>Mínimo 8 caracteres</p>
        </div>
        <div className={styles.buttons}>
          <Link to="/" className='disabled'>Cancelar</Link>
          <button type='button' onClick={changePassword}>Guardar</button>
        </div>
      </form>
    </div>
  );
}

export default ResetPass;
