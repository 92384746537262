import React from 'react';
import styles from './styles.module.scss';

import LostPassComponent from '../../components/pass/lost';

const LostPass = () => {
  return (
    <div className={styles.container}>
      <LostPassComponent />
    </div>
  );
};

export default LostPass;
