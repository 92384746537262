export const email_validation = {
  name: "email",
  label: "Email",
  type: "email",
  id: "email",
  placeholder: "",
  validation: {
    required: {
      value: true,
      message: "email es requerido",
    },
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: "email invalido",
    },
  },
};

export const password_validation = {
  name: "password",
  label: "Contraseña",
  type: "password",
  id: "password",
  placeholder: "",
  validation: {
    required: {
      value: true,
      message: "password es requerido",
    },
    minLength: {
      value: 8,
      message: "minimo 8 caracteres",
    },
  },
};

export const name_validation = {
  name: "name",
  label: "Nombre",
  type: "text",
  id: "name",
  placeholder: "",
  validation: {
    required: {
      value: true,
      message: "nombre es requerido",
    },
    minLength: {
      value: 3,
      message: "minimo 3 caracteres",
    },
  },
}

export const lastname_validation = {
  name: "lastname",
  label: "Apellido",
  type: "text",
  id: "lastname",
  placeholder: "",
  validation: {
    required: {
      value: true,
      message: "apellido es requerido",
    },
    minLength: {
      value: 3,
      message: "minimo 3 caracteres",
    },
  },
}