import React from 'react';
import { RouterProvider } from 'react-router-dom';

import mainRouter from './routes/rootRoutes';
import { AuthProvider } from './context/AuthContext';
import { SocketProvider } from './context/SocketContext';

const App = () => {
  return (
    <AuthProvider>
      <SocketProvider>
        <RouterProvider router={mainRouter} />
      </SocketProvider>
    </AuthProvider>
  )
};

export default App;

