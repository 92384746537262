import httpClient from './httpClient'

const path = 'vtex'

const apiVtex = {
    async getAllCategories() {
        try {
            const response = await httpClient.get(`${path}/getAllCategories`)
            return response
        } catch(err) {
            return err
        }
    },
    async getAllBrands() {
        try {
            const response = await httpClient.get(`${path}/getAllBrands`)
            return response
        } catch( err ) {
            return err
        }
    },
    async updateSubCategoryWinPercentange() {
        try {
            const response = await httpClient.get(`${path}/updateSubCategoryWinPercentange`)
            return response
        } catch(err) {
            return err
        }
    }
}

export default apiVtex