import React, { useState } from 'react';
import styles from './create.module.scss';
import { Input } from '../login/input';
import { email_validation, lastname_validation, name_validation, password_validation } from '../../utils/inputValidations';
import { FormProvider, useForm } from 'react-hook-form';
import apiUser from '../../api/apiUsers';

const Create = ({ setActionUser }) => {

  const [ loading, setLoading ] = useState(false)
  const methods = useForm()

  const onSubmit = methods.handleSubmit(async(data) => {
    setLoading(true)
    
    setActionUser(true)
    try {
      const resp = await apiUser.createUser( data )
      if ( resp && ( resp.code === 201 || resp.code === 200 ) ) {
        setActionUser(false)
        setLoading(false)
        methods.reset()
      } else {
        setLoading(false)
        methods.reset()
      }
    } catch ( err ) {
      setLoading(false)
      methods.reset()
    }
  })

  return (
    <div className={styles.container}>
      <h3>Agregar nuevo usuario</h3>
      <FormProvider {...methods}>
        <form
          onSubmit={ e => e.preventDefault() }
          noValidate
          className={styles.form}
        >
          <Input {...name_validation}/>
          <Input {...lastname_validation}/>
          <Input {...email_validation}/>
          <Input {...password_validation}/>
          <div className={styles.button}>
            <button 
              type='button'
              onClick={ onSubmit }
              disabled={ loading }
            >{ loading ? 'Registrando' : 'Registrar'}</button>
          </div>
        </form>
      </FormProvider>
      {/* <form className={styles.form}>
        <div className={styles.field}>
          <p>Nombre</p>
          <label>
            <input type='text' />
          </label>
        </div>
        <div className={styles.field}>
          <p>Apellido</p>
          <label>
            <input type='text' />
          </label>
        </div>
        <div className={styles.field}>
          <p>Email</p>
          <label>
            <input type='email' />
          </label>
        </div>
        <div className={styles.field}>
          <p>Contraseña</p>
          <label>
            <input type='password' />
          </label>
        </div>
        <div className={styles.button}>
          <button type='button'>Guardar</button>
        </div>
      </form> */}
    </div>
  );
}

export default Create;
