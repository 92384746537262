import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

import logo from '../../assets/images/logo.png';
import { Input } from './input';
import { FormProvider, useForm } from 'react-hook-form';
import { email_validation, password_validation } from '../../utils/inputValidations';
import { AuthContext } from '../../context/AuthContext';


const Login = () => {
  const { auth, login } = useContext( AuthContext )
  const [ loading, setLoading ] = useState(false)
  const methods = useForm()
  const navigate = useNavigate()

  useEffect(() => {
    if ( auth.logged ) {
      navigate('/', { replace: true })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [auth, navigate])

  const onSubmit = methods.handleSubmit((data) => {
    (async() => {
      setLoading(true)
      try {
        const resp = await login( data.email, data.password )
        if ( resp.ok ) {
          setLoading(false)
          navigate('/', { replace: true })
        } else {
          console.log('usuario o contraseña incorrectos')
        }
      } catch ( err ) {
        setLoading(false)
      }
    })()
  })

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src={logo} alt='Dusof' />
      </div>
      <FormProvider {...methods}>
        <form
        onSubmit={ e => e.preventDefault() }
        noValidate
        className={styles.form}>
          <Input {...email_validation}/>
          <Input {...password_validation}/>
          <div className={styles.button}>
            <button 
              type='button'
              onClick={ onSubmit }
              disabled={ loading }
            >{ loading ? 'Ingresando' : 'Entrar'}</button>
          </div>
          <div className={styles.reset}>
            <Link to="/login/lost-pass">olvidé mi contraseña</Link>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default Login;
