import httpClient from './httpClient'

const path = 'exel'

const apiExel = {
    
    async updateInventoryExel() {
        try {
            const response = httpClient.get(`${path}/products`)
            return response
        } catch ( err ) {
            return err
        }
    },
    async getNewProducts( page, limit, term ) {
        try {
            const response = await httpClient.get(`${path}/getNewProducts?page=${page}&limit=${limit}&term=${term}`)
            return response
        } catch ( err ) {
            return err
        }
    },
    async createVtexProduct( vtexProducts ) {
        try {
            const response = await httpClient.post(`${path}/createVtexProduct`, { products: vtexProducts })
            return response
        } catch( err ) {
            return err
        }
    }
}

export default apiExel