import React, { useState } from 'react';
import styles from './search.module.scss';

const Search = ({ setSearchTerm, setCurrentPage }) => {

  const [ term, setTerm ] = useState('')

  const searchProduct = (e) => {
    let termInput = e.target.value

    if( termInput !== '' ){
      setTerm(termInput)
    } else {
      setSearchTerm('')
    }
  }

  const sendSearch = () => {
    if( term !== '' ){
      setCurrentPage(1)
      setSearchTerm(term)
    }
  }

  return (
    <div className={styles.container}>
      <form className={styles.form}>
        <label className={styles.label}>
          <input onChange={searchProduct} placeholder='Buscar por SKU, Categoría o Descripción' />
        </label>
        <div className={styles.button}>
          <button onClick={sendSearch} type='button'>Buscar</button>
        </div>
      </form>
    </div>
  );
}

export default Search;
