import React from 'react';
import styles from './filters.module.scss';

const Filters = ({ setDays, days }) => {

  function selectDays( day ){
    setDays( day )
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <button type='button' className={days === 30 ? styles.selected : ''} onClick={ () => selectDays( 30 )}>Últimos 30 días</button>
        <button type='button' className={days === 15 ? styles.selected : ''} onClick={ () => selectDays( 15 )}>Últimos 15 días</button>
        <button type='button' className={days === 7 ? styles.selected : ''} onClick={ () => selectDays( 7 )}>Últimos 7 días</button>
      </div>
    </div>
  );
}

export default Filters;
