import httpClient from './httpClient'

const path = 'parameters'

const apiParameters= {
    async getParameters() {
        try {
            const response = await httpClient.get(`${ path }/getParameters`)
            return response
        } catch ( err ) {
            return err
        }
    },
    async setCronjob( cronHour ) {
        try {
            const response = await httpClient.post(`${ path }/setCronjob`, { cronHour: cronHour })
            return response
        } catch( err ) {
            return err
        }
    },
    async setMinInventory( minInventory ) {
        try {
            const response = await httpClient.post(`${ path }/setMininventory`, { minInventory: minInventory })
            return response
        } catch ( err ) {
            return err
        }
    }
    // async updateWinPercentage( categories ) {
    //     try {
    //         const response = await httpClient.put(`${path}/updateWinPercentage`, {categories: categories})
    //         return response
    //     } catch ( err ) {
    //         return err
    //     }
    // }
}

export default apiParameters