import { createContext, useContext, useEffect, useReducer } from "react";
import useSocket from "../hooks/useSocket";
import { AuthContext } from "./AuthContext";
import { socketReducer } from '../reducer/socketReducer';
import { types } from '../types/types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const socketSwal = withReactContent(Swal)

export const SocketContext = createContext();

const initialState = {
  processStarted: {},
  exel: {
    step: "start"
  },
  cva: {
    step: "start"
  },
  ingram: {
    step: "start"
  },
  gloma: {
    step: "start"
  },
  vtex: {
    message: ""
  }
}

export const SocketProvider = ({ children }) => {
  const url = process.env.REACT_APP_IO_URL;
  const { socket, online, connectSockets, disconnectSockets } = useSocket(url);
  const { auth } = useContext(AuthContext);
  const [socketState, dispatch] = useReducer(socketReducer, initialState)

  useEffect(() => {
    if (auth.logged) {
      connectSockets(auth.token);
    } else {
      disconnectSockets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    socket?.on("updateExelInventory", (payload) => {
      dispatch({
        type: types.updateExelInventory,
        payload: payload
      })
    });
  }, [socket, dispatch]);

  useEffect(() => {
    socket?.on("updateCvaInventory", (payload) => {
      dispatch({
        type: types.updateCvaInventory,
        payload: payload
      })
    })
  }, [socket, dispatch]);

  useEffect(() => {
    socket?.on("updateIngramInventory", (payload) => {
      dispatch({
        type: types.updateIngramInventory,
        payload: payload
      })
    })
  }, [socket, dispatch]);

  useEffect(() => {
    socket?.on("updateGLomaInventory", (payload) => {
      dispatch({
        type: types.updateGLomaInventory,
        payload: payload
      })
    })
  }, [socket, dispatch]);

  useEffect(() => {
    socket?.on("createProducts", (payload) => {
      const { ok, response } = payload.message
      if ( ok ) {
        const { total_errores, total_productos_creados } = response
        socketSwal.fire({
          title: <p>Información de Productos</p>,
          html: `<p>productos creados: ${total_productos_creados}</p> <p>incompletos: ${total_errores}</p>`,
          confirmButtonColor: '#F57E00'
        })
      }
      dispatch({
        type: types.createProducts,
        payload: payload
      })
    })
  }, [socket, dispatch]);

  useEffect(() => {
    socket?.on("processStarted", (payload) => {
      dispatch({
        type: types.processStarted,
        payload: payload
      })
    });
  },[socket,dispatch])

  return (
    <SocketContext.Provider value={{ socket, online, dispatch, socketState}}>
      {children}
    </SocketContext.Provider>
  );
};
