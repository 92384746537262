import React, { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import styles from './styles.module.scss'

import Loading from '../reuse/loading'
import { SocketContext } from '../../context/SocketContext'
import apiExel from '../../api/apiExel'
import apiCva from '../../api/apiCva'
import apiGLoma from '../../api/apiGloma'
import apiIngram from '../../api/apiIngram'

const Sync = () => {
  const { socketState } = useContext(SocketContext)
  let { distributor } = useParams()
  let stepSocket = socketState?.[distributor].step
  let message = socketState?.[distributor].message
  let percent = socketState?.[distributor].percent

  const updateInventory = () => {
    switch (distributor) {
      case 'exel':
        return apiExel.updateInventoryExel()
      case 'cva':
        return apiCva.updateInventoryCva()
      case 'gloma':
        return apiGLoma.updateInventoryGLoma()
      case 'ingram':
        return apiIngram.updateInventoryIngram()
      default:
        return
    }
  }

  let status = 'Haz seleccionado'

  switch (stepSocket) {
    case 'retrieving':
      status = `Descargando información`
      break
    case 'start':
      status = 'Haz seleccionado'
      break
    case 'processing':
      status = 'Actualizando precios e inventarios'
      break
    case 'updateInventory':
      status = 'Actualizando inventario'
      break
    case 'updatePrices':
      status = 'Actualizando precios'
      break
    case 'success':
      status = 'Finalizado exitosamente'
      break
    case 'error':
      status = 'Ha ocurrido un problema'
      break
    default:
      status = 'Haz seleccionado'
  }

  return (
    <div className={styles.container}>
      <div className={styles.status}>
        <p>{status}</p>
      </div>
      <div className={styles.title}>
        {stepSocket !== 'start' && (
          <p>{message}</p>
        )}
        {stepSocket === 'start' && (
        <><p>Sincronización de inventario</p><p>{distributor}</p></>
        )}
      </div>
      {(stepSocket === 'processing' || stepSocket === 'retrieving' || stepSocket === 'updateInventory' || stepSocket === 'updatePrices') && (
        <div className={styles.loading}>
          <div className={styles.progress}>
            <p>{`${Number(percent).toFixed(2)}%`}</p>
          </div>
          <Loading />
        </div>
      )}
      {stepSocket === 'success' && (
        <div className={styles.completed}>
          <p>100%</p>
        </div>
      )}
      {stepSocket === 'error' && (
        <div className={styles.error}>
          <p>Ha ocurrido un error en el proceso.</p>
          <p>Por favor intenta de nuevo.</p>
        </div>
      )}
      {stepSocket === 'start' && (
        <div className={styles.button}>
          <button type="button" onClick={() => updateInventory()}>
            Iniciar sincronización
          </button>
        </div>
      )}
      {stepSocket === 'success' && (
        <div className={styles.button}>
          <Link to="/">Ir al Dashboard</Link>
        </div>
      )}
      {stepSocket === 'error' && (
        <div className={styles.button}>
          <button type="button" onClick={() => updateInventory()}>
            Intentar de nuevo
          </button>
        </div>
      )}
    </div>
  )
}

export default Sync
