import React from 'react';
import styles from './dashboard.module.scss';

import Header from '../reuse/header';
import { SyncBox, UploadBox, CategoryBox, ReportBox, UserBox, ParametersBox } from './box';

const Dashboard = () => {
  return (
    <div className={styles.container}>
      <Header title="Bienvenido a DataSync Dusof" description="Elige la opción de sincronización que deseas actualizar y te llevaremos por el proceso paso a paso." />
      <div className={styles.content}>
        <SyncBox />
        <UploadBox />
        <CategoryBox />
        <ReportBox />
        <UserBox />
        <ParametersBox />
      </div>
    </div>
  );
}

export default Dashboard;
