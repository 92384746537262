import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

import Header from '../../components/reuse/header';
import Filters from '../../components/report/filters';
import Table from '../../components/report/table';
import apiReport from '../../api/apiReport';

const Report = () => {
  const [reports, setReports] = useState([])
  const [days, setDays] = useState(30)

  useEffect(() => {

    switch( days ) {
      case 30:
        (async() => {
          try {
            const { response } = await apiReport.getThirtyDays()
            setReports(response)
          } catch ( err ) {
            console.log(err)
          }
        })()
        break

      case 15:
        (async() => {
          try {
            const { response } = await apiReport.getFifteenDays()
            setReports(response)
          } catch ( err ) {
            console.log(err)
          }
        })()
        break
      
      case 7:
        (async() => {
          try {
            const { response } = await apiReport.getSevenDays()
            setReports(response)
          } catch ( err ) {
            console.log(err)
          }
        })()
        break

      default:
        (async() => {
          try {
            const { response } = await apiReport.getThirtyDays()
            setReports(response)
          } catch ( err ) {
            console.log(err)
          }
        })()
        break
    }
  },[days])

  return (
    <div className={styles.container}>
      <Header title="Reporte de altas" description="Selecciona el rango de tiempo del que deseas ver que productos se dieron de alta." />
      <div className={styles.content}>
        <Filters setDays={ setDays } days={ days }/>
        <Table reports={ reports }/>
      </div>
    </div>
  );
}

export default Report;
