import { createBrowserRouter, redirect } from 'react-router-dom'

import GuestLayout from '../components/layout/guest'
import UserLayout from '../components/layout/user'
import Login from '../containers/login'
import LostPass from '../containers/lost-pass'
import apiUser from '../api/apiUsers'
import Dashboard from '../containers/dashboard'
import Users from '../containers/users'
import Sync from '../containers/sync'
import Upload from '../containers/upload'
import Category from '../containers/category'
import Parameters from '../containers/parameters'
import ResetPass from '../containers/reset-pass'
import Report from '../containers/report'
import Error from '../containers/error'

const mainRouter = createBrowserRouter([
  {
    path: 'login',
    element: <GuestLayout />,
    children: [
      { index: true, element: <Login /> },
      { path: 'lost-pass', element: <LostPass /> },
      { path: '*', element: <Error /> },
    ],
  },
  {
    path: '/',
    element: <UserLayout />,
    loader: async () => {
      const token = await sessionStorage.getItem('auth_token')
      if (!token) {
        throw redirect('/login')
      }
      return null
    },
    children: [
      { index: true, element: <Dashboard /> },
      {
        path: 'users',
        element: <Users />,
        loader: async () => {
          return await apiUser.getUsers(1, 50)
        },
      },
      { path: 'sync/:distributor', element: <Sync /> },
      {
        path: 'upload/:distributor',
        element: <Upload />,
      },
      { path: 'report', element: <Report /> },
      {
        path: 'categories',
        element: <Category />,
      },
      { path: 'parameters', element: <Parameters /> },
      { path: 'reset-pass', element: <ResetPass /> },
      { path: '*', element: <Error /> },
    ],
  },
])

export default mainRouter
