import httpClient from './httpClient'

const path = 'user'

const apiUser = {
    
    async getUsers( page, limit) {
        try {
            const response = httpClient.get(`${path}/getAllUsers?page=${page}&limit=${limit}`)
            return response
        } catch ( err ) {
            return err
        }
    },

    async createUser( user ) {
        try {
            const response = await httpClient.post(`${path}/create`, {user: user})
            return response
        } catch ( err ) {
            return err
        }
    },

    async changeRole( id, role ) {
        try {
            const response = await httpClient.put(`${path}/changeRole`, { id, role })
            return response
        } catch ( err ) {
            return err
        }
    },

    async deleteUser( id ) {
        try {
            const response = await httpClient.delete(`${path}/deleteUser/${id}`)
            return response
        } catch( err ) {
            return err
        }
    },

    async changePassword( email, password, newPassword ) {
        try {
            const response = await httpClient.put(`${path}/changePassword`, { email, password, newPassword })
            return response
        } catch( err ) {
            return err
        }
    },

    async resetPassword( email ) {
        try {
            const response = await httpClient.put(`${path}/resetPassword`, { email: email })
            return response
        } catch( err ) {
            return err
        }
    }
}

export default apiUser