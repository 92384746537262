import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

import Header from '../../components/reuse/header';
import Search from '../../components/upload/search';
import Table from '../../components/upload/table';
import Pagination from '../../components/upload/pagination';
import { useParams } from 'react-router-dom';
import apiExel from '../../api/apiExel';
import apiCva from '../../api/apiCva';
import apiGLoma from '../../api/apiGloma';
import apiIngram from '../../api/apiIngram';
import apiVtex from '../../api/apiVtex';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const loadSwal = withReactContent(Swal)

const Upload = () => {

  const [ searchTerm, setSearchTerm ] = useState('')
  const [ products, setProducts ] = useState([])
  const [ total, setTotal ] = useState(0)
  const [ currentPage, setCurrentPage ] = useState(1)
  const [ categories, setCategories ] = useState([])
  const [ brands, setBrands ] = useState([])
  const [ loading, setLoading ] = useState(true)

  let { distributor } = useParams()


  useEffect(() => {
    if ( loading ) {
      loadSwal.fire({
        title: `cargando productos de ${distributor}`,
        didOpen: () => {
          loadSwal.showLoading()
        }
      })
    }

    if (!loading) {
      loadSwal.close()
    }
  }, [loading, distributor])

  useEffect(() => {
    
    (async() => {
      try {
        const getCategories = await apiVtex.getAllCategories()
        const getBrands = await apiVtex.getAllBrands()

        setCategories(getCategories.response)
        setBrands(getBrands.response)
      } catch( err ) {
        console.log(err)
      }
    })()
  },[])

  useEffect(() => {
    (async() => {
      setLoading(true)
      try {
        switch( distributor ){
          case 'exel':
            const exelResponse = await apiExel.getNewProducts( currentPage, 200, searchTerm )
            setProducts(exelResponse.response.products)
            setTotal(exelResponse.response.total)
            setLoading(false)
            return
          case 'cva':
            const cvaResponse = await apiCva.getNewProducts( currentPage, 200, searchTerm )
            setProducts(cvaResponse.response.products)
            setTotal(cvaResponse.response.total)
            setLoading(false)
            return
          case 'gloma':
            const glomaResponse = await apiGLoma.getNewProducts( currentPage, 200, searchTerm )
            setProducts(glomaResponse.response.products)
            setTotal(glomaResponse.response.total)
            setLoading(false)
            return
          case 'ingram':
            const ingramResponse = await apiIngram.getNewProducts( currentPage, 200, searchTerm )
            setProducts(ingramResponse.response.products)
            setTotal(ingramResponse.response.total)
            setLoading(false)
            return
          default:
            return
        }
      } catch( err ) {
        console.log(err)
      }
    })()
  },[currentPage, searchTerm, distributor])

  return (
    <div className={styles.container}>
      <Header title={`Alta de productos - ${distributor.toUpperCase()}`} description="Selecciona los productos que deseas dar de alta en tu catálogo." />
      <Search setSearchTerm={setSearchTerm} setCurrentPage={ setCurrentPage }/>
      <div className={styles.content}>
        {/* <Status /> */}
        <Pagination totalItems={ total } currentPage={ currentPage } setCurrentPage={ setCurrentPage }/>
        <Table categories={categories} brands={brands} products= { products } searchTerm={searchTerm} />
      </div>
    </div>
  );
};

export default Upload;
