import httpClient from './httpClient'

const path = 'reports'

const apiReport = {
    
    async getThirtyDays() {
        try {
            const response = await httpClient.get(`${path}/thirty`)
            return response
        } catch ( err ) {
            return err
        }
    },
    async getFifteenDays() {
        try {
            const response = await httpClient.get(`${path}/fifteen`)
            return response
        } catch ( err ) {
            return err
        }
    },
    async getSevenDays() {
        try {
            const response = await httpClient.get(`${path}/seven`)
            return response
        } catch ( err ) {
            return err
        }
    }
}

export default apiReport