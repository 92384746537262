import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import apiParameters from '../../api/apiParameters';

const Inventory = ({ minInventory, setParameters, cronjob }) => {
  const [inventory, setInventory] = useState(minInventory)

  const setMininventory = () => {
    (async () => {
      try {
        const response = await apiParameters.setMinInventory(inventory)
        if ( response.ok ) {
          setParameters({
            cronjob: cronjob,
            minInventory: inventory
          })
        }
      } catch( err ) {
        console.log(err)
      }
    })() 
  }

  const handleChange = event => {
    setInventory(event.target.value)
  }

  return (
    <div className={styles.component}>
      <p>{`Inventario mínimo - Valor actual: ${minInventory}`}</p>
      <form className={styles.form}>
        <label className={styles.label}>
          <input type='number' onChange={ handleChange }/>
        </label>
        <div className={styles.button}>
          <button type='button' onClick={ setMininventory }>Guardar</button>
        </div>
      </form>
    </div>
  );
}

const Schedule = ({ cronjob, setParameters, minInventory }) => {
  const [selected, setSelected] = useState()
  const fixCronjob = cronjob < 10 ? `0${cronjob}` : cronjob

  const setCronjob = () => {
    (async() => {
      try {
        const response = await apiParameters.setCronjob(selected)
        if ( response.ok ) {
          setParameters({
            cronjob: selected,
            minInventory: minInventory
          })
        }
      } catch( err ) {
        console.log(err)
      }
    })()
  }

  const handleChange = event => {
    setSelected(event.target.value)
  }

  return (
    <div className={styles.component}>
      <p>{`Hora de ejecución automática - Valor actual: ${fixCronjob}:00`}</p>
      <form className={styles.form}>
        <label className={styles.label}>
          <select value={ selected } onChange={ handleChange }>
            <option value="1">01:00</option>
            <option value="2">02:00</option>
            <option value="3">03:00</option>
            <option value="4">04:00</option>
            <option value="5">05:00</option>
            <option value="6">06:00</option>
            <option value="7">07:00</option>
            <option value="8">08:00</option>
            <option value="9">09:00</option>
            <option value="10">10:00</option>
            <option value="11">11:00</option>
            <option value="12">12:00</option>
            <option value="13">13:00</option>
            <option value="14">14:00</option>
            <option value="15">15:00</option>
            <option value="16">16:00</option>
            <option value="17">17:00</option>
            <option value="18">18:00</option>
            <option value="19">19:00</option>
            <option value="20">20:00</option>
            <option value="21">21:00</option>
            <option value="22">22:00</option>
            <option value="23">23:00</option>
            <option value="0">00:00</option>
          </select>
        </label>
        <div className={styles.button}>
          <button type='button' onClick={ setCronjob }>Guardar</button>
        </div>
      </form>
    </div>
  );
}

const Parameters = ({ cronjob, minInventory, setParameters }) => {
  return (
    <div className={styles.container}>
      <Schedule cronjob={ cronjob } setParameters={ setParameters } minInventory={ minInventory } />
      <Inventory minInventory={ minInventory } setParameters={ setParameters } cronjob={ cronjob }/>
    </div>
  );
}

export default Parameters;
