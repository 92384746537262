import React from 'react';
import styles from './styles.module.scss';

const Error = () => {
  return (
    <div className={styles.container}>
      <div>Error</div>
    </div>
  );
};

export default Error;
