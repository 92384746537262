import React from 'react';
import styles from './styles.module.scss';

import SyncComponent from '../../components/sync';

const Sync = () => {
  return (
    <div className={styles.container}>
      <SyncComponent />
    </div>
  );
};

export default Sync;
