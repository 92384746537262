import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './lost.module.scss';

import logo from '../../assets/images/logo.png';
import apiUser from '../../api/apiUsers';


const LostPass = () => {
  const [email, setEmail] = useState()
  const navigate = useNavigate()

  const resetPassword = () => {
    (async() => {
      try {
        const response = await apiUser.resetPassword(email)
        if ( response.ok ) {
          navigate('/', { replace: true })
        }
      } catch( err ) {
        console.log( err )
      }
    })()
  }
  
  const handleChange = event => {
    setEmail(event.target.value)
  }

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src={logo} alt='Dusof' />
      </div>
      <form className={styles.form}>
        <div className={styles.field}>
          <p>Email</p>
          <label>
            <input type='email' placeholder='usuario@dusof.com' onChange={ handleChange }/>
          </label>
        </div>
        <div className={styles.text}>
          <p>Recibirás en tu correo una contraseña autogenerada</p>
        </div>
        <div className={styles.button}>
          <button type='button' onClick={ resetPassword }>Enviar</button>
        </div>
        <div className={styles.login}>
          <Link to="/auth">iniciar sesión</Link>
        </div>
      </form>
    </div>
  );
}

export default LostPass;
