import React, { useEffect, useState } from 'react';
import styles from './table.module.scss';

import icons from '../../assets/icons'
import apiExel from '../../api/apiExel';
import { useNavigate, useParams } from 'react-router-dom';
import apiCva from '../../api/apiCva';
import apiGLoma from '../../api/apiGloma';
import apiIngram from '../../api/apiIngram';

let arrayProducts = [];

const CategorySelector = ( {categories, setSelectedCategory, selected, sku, setCategoryText} ) => {

  const chooseCategory = (e) => {
    setSelectedCategory(e.target.value);

    if( selected ){
      let productFinded = arrayProducts.find(pr => pr?.sku === sku)
      let categoryChildren = categories?.find(cat => cat.categoryId === Number(e.target.value))
      if( productFinded ){
        productFinded.categoriaVtex = Number(e.target.value);
        productFinded.departmentId = Number(categoryChildren.departmentId)
        productFinded.categoryText = categoryChildren?.name
      }

      let newArrayProducts = arrayProducts.filter(arrPr => arrPr?.sku !== sku)
      arrayProducts = newArrayProducts
      arrayProducts.push(productFinded)
    }
  }

  useEffect(() => {
    setCategoryText(categories[0].name)
    setSelectedCategory(categories[0].categoryId)
  }, [])

  return (
    <div>
      <label className={styles.selector}>
        <select
          onChange={chooseCategory}
        >
          <option disabled defaultValue>Seleccionar</option>
          {
            categories.map((category) => (
              <option key={category.categoryId} value={category.categoryId}>{category.name}</option>
            ))
          }
        </select>
      </label>
    </div>
  );
}

const BrandSelector = ({ setSelectedBrand, selected, sku, brands }) => {
  const chooseBrand = (e) => {
    setSelectedBrand(e.target.value);

    if( selected ){
      let productFinded = arrayProducts.find(pr => pr.sku === sku);
      productFinded.marcaId = Number(e.target.value);

      let newArrayProducts = arrayProducts.filter(arrPr => arrPr.sku !== sku);
      arrayProducts = newArrayProducts;
      arrayProducts.push(productFinded);
    }
  }

  useEffect(() => {
    setSelectedBrand(2000054);
  }, [setSelectedBrand]);

  return (
    <div>
      <label className={styles.selector}>
        <select
          onChange={chooseBrand}
        >
          <option disabled defaultValue>Seleccionar</option>
          {brands.map((brand) => (
            <option key={brand.id} value={brand.id}>{brand.name}</option>
          ))}
        </select>
      </label>
    </div>
  );
}

const Titles = () => (
  <div className={styles.titles}>
    <div>
      <img src={icons.check} alt="Seleccionar" />
    </div>
    <div>
      <p>SKU</p>
    </div>
    <div>
      <p>Inventario</p>
    </div>
    <div>
      <p>Vendedor</p>
    </div>
    <div>
      <p>Categoría</p>
    </div>
    <div>
      <p>Descripción</p>
    </div>
    <div>
      <p>Marca</p>
    </div>
    <div>
      <p>Categoría VTEX</p>
    </div>
  </div>
);

const Row = ({ sku, inventory, category, seller, description, categories, brands, cleanProducts, setCleanProducts }) => {
  const [selected, setSelected] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedBrand, setSelectedBrand] = useState();
  const [categoryText, setCategoryText] = useState('');

  const selectProduct = (e) => {
    setSelected(!selected);
    let categoryChildren = categories?.find(cat => cat.categoryId === Number(selectedCategory));
    if(e.target.checked){
      const product = {
        sku: sku,
        inventory: inventory,
        category: category,
        categoryText: categoryText,
        description: description,
        marcaId: Number(selectedBrand),
        departmentId: categoryChildren?.departmentId,
        categoriaVtex: Number(selectedCategory),
      }
      arrayProducts.push(product);
    } else {
      let newArrayProducts = arrayProducts.filter(pr => pr.sku !== sku);
      arrayProducts = newArrayProducts;
    }
  }

  useEffect(() => {
    if( cleanProducts ){
      setSelected(false);
      setCleanProducts(false);
    }
  }, [cleanProducts]);

  return (
    <div className={`${styles.row} ${selected ? styles.selected : ''}`}>
      <div>
        <label>
          <input type='checkbox' onChange={(e) => selectProduct(e)} checked={selected} />
        </label>
      </div>
      <div>
        <p>{sku}</p>
      </div>
      <div>
        <p>{inventory}</p>
      </div>
      <div>
        <p>{seller}</p>
      </div>
      <div>
        <p>{category}</p>
      </div>
      <div>
        <p>{description}</p>
      </div>
      <div>
        <BrandSelector setSelectedBrand={setSelectedBrand} selected={selected} sku={sku} brands={ brands } />
      </div>
      <div>
        <CategorySelector categories={ categories } setSelectedCategory={setSelectedCategory} selected={selected} sku={sku} setCategoryText={setCategoryText} />
      </div>
    </div>
  );
}

const Table = (props) => {
  const cleanCategories = []
  const navigate = useNavigate()
  const [cleanProducts, setCleanProducts] = useState(false)
  const { distributor } = useParams()

  for( let category of props.categories ) {
    if( category.children.length > 0 ) {
      for ( let children of category.children ) {
        const cleanCategory = {
          parentId: category.id,
          categoryId: children.id,
          name: `${category.name} > ${children.name}`,
          departmentId: category.id
        }
        cleanCategories.push(cleanCategory)
      }
    }
  }

  const sendProducts = () => {
    (async () => {
      try {
        switch( distributor ){
          case 'exel':
            await apiExel.createVtexProduct(arrayProducts)
            break
          case 'cva':
            await apiCva.createVtexProduct(arrayProducts)
            break
          case 'gloma':
            await apiGLoma.createVtexProduct(arrayProducts)
            break
          case 'ingram':
            await apiIngram.createVtexProduct(arrayProducts)
            break
          default:
            break
        }
        setCleanProducts(true);
        arrayProducts = [];
        navigate('/', { replace: true })
      } catch( err ) {
        console.log(err)
      }
    }) ()
  }

  return (
    <div className={styles.container}>
      <Titles />
      <div className={styles.content}>
        {
          (props.products || []).map((row, key) => 
          <Row 
            key={key} 
            sku={row.sku} 
            inventory={row.inventory} 
            category={row.category} 
            description={row.description} 
            categories={ cleanCategories } 
            cleanProducts={cleanProducts}
            setCleanProducts={setCleanProducts}
            brands={ props.brands }
          />)
        }
      </div>
      <div className={styles.submit}>
        <button type='button' onClick={sendProducts}>Cargar seleccionados</button>
      </div>
    </div>
  );
}

export default Table;
